import { InputLabel, InputLabelProps } from '@mui/material';

const InputLabelC = (props: InputLabelProps) => (
    <InputLabel
        sx={theme => ({
            backgroundColor: theme.palette.background.paper,
            padding: '0 6px',
            left: '-4px',
            marginBottom: 3,
        })}
        {...props}
    />
);

export default InputLabelC;
