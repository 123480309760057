import '@src/index.css';

import { ApolloProvider } from '@apollo/client';
import { LanguageProvider } from '@modules/language';
import { grey } from '@mui/material/colors';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '@src/hooks';
import { SnackbarProvider } from 'notistack';
import { StrictMode, useContext, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IntlProvider, MessageFormatElement } from 'react-intl';

import { IAuthContext } from './assets/types/auth';
import { DirtyContextProvider } from './components/hierarchies/shared/DirtyContext';
import { Routes } from './components/layout/Routes';
import { LinearLoader } from './components/shared/LinearLoader';
import { SnackbarUtilsConfigurator } from './components/shared/SnackBarUtils';
import { SettingProvider } from './modules/setting/SettingProvider';
import { SettingsContext } from './modules/setting/SettingsContext';
import BlockActionContextProvider from './store/BlockActionContext/BlockActionContext.provider';
import JobSubscriptionContextProvider from './store/JobSubscriptionContext/JobSubscriptionContext.provider';
import { getApolloClient } from './utils/generic/getApolloClient';
// fix type
type LangImportType = typeof import('@src/assets/lang/compiled-lang/fr.json');

const loadLocaleData = (locale: string): Promise<LangImportType> => {
    // TODO REST call per lang.json
    switch (locale) {
        case 'fr':
            return import('@src/assets/lang/compiled-lang/fr.json');
        default:
            return import('@src/assets/lang/compiled-lang/en.json');
    }
};

const locale = navigator.language.substring(0, 2);

declare module '@mui/material/styles' {
    interface Palette {
        disabled: string;
    }
    interface PaletteOptions {
        disabled: string;
    }

    interface ZIndex {
        topComponent: number;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        disabled: true;
    }
}

function ThemedApp({ sessionData }: { sessionData: IAuthContext['sessionData'] }) {
    const { theme: themeSettings } = useContext(SettingsContext);
    // const [darkMode, setDarkMode] = useState<boolean>(localStorage.getItem('darkMode') === 'true');
    const darkMode = false;
    // useEffect(() => {
    //     localStorage.setItem('darkMode', String(darkMode));
    // }, [darkMode]);

    /**
     * Use this theme along all the app
     * - primary: main/light/dark blue
     * - secondary: main/light/dark orange
     */
    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: darkMode ? 'dark' : 'light',
                    primary: {
                        main:
                            sessionData?.attributes?.primaryColor ??
                            themeSettings?.primaryColor ??
                            '#001560',
                    },
                    secondary: {
                        main:
                            sessionData?.attributes?.secondaryColor ??
                            themeSettings?.secondaryColor ??
                            '#057bff',
                    },
                    disabled: grey[200],
                },
                components: {
                    MuiListItemText: { styleOverrides: { primary: { fontSize: '0.9em' } } },
                    MuiFormControl: {
                        defaultProps: {
                            size: 'small',
                        },
                    },
                    MuiButton: {
                        defaultProps: {
                            size: 'small',
                        },
                    },
                },
                zIndex: {
                    topComponent: 2000,
                },
            }),
        [darkMode, sessionData],
    );
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <DirtyContextProvider>
                    <BlockActionContextProvider>
                        <DndProvider backend={HTML5Backend}>
                            <SnackbarProvider maxSnack={3}>
                                <JobSubscriptionContextProvider>
                                    <SnackbarUtilsConfigurator />
                                    <Routes />
                                </JobSubscriptionContextProvider>
                            </SnackbarProvider>
                        </DndProvider>
                    </BlockActionContextProvider>
                </DirtyContextProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
function App(): JSX.Element {
    const { initialize, isReady, error, sessionData } = useAuth();

    const [messages, setMessages] = useState<
        Record<string, string> | Record<string, MessageFormatElement[]>
    >({});

    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        if (!isReady) return;
        loadLocaleData(locale).then(setMessages);
    }, [isReady]);

    const apolloClient = getApolloClient();

    return !isReady ? (
        <LinearLoader message={error?.message} /> // TODO: remove this string
    ) : (
        <StrictMode>
            <ApolloProvider client={apolloClient}>
                <SettingProvider>
                    <LanguageProvider>
                        <IntlProvider
                            messages={messages}
                            locale={locale}
                            defaultLocale="en"
                            onError={err => {
                                if (err.code === 'MISSING_TRANSLATION') {
                                    // TODO: log the error
                                    return;
                                }
                                // eslint-disable-next-line no-console
                                console.error(err);
                            }}
                        >
                            <ThemedApp sessionData={sessionData} />
                        </IntlProvider>
                    </LanguageProvider>
                </SettingProvider>
            </ApolloProvider>
        </StrictMode>
    );
}

export default App;
