import { SelectFieldElement } from '@src/components/formFields';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import InputLabelC from './components/shared/InputLabelC';
// import { useAuth } from './hooks';

type RoleComponentInterface = {
    control: Control<any>;
    message?: string | false;
    name?: string;
    label?: string;
    required?: boolean;
};

export type RealmRolesInterface = {
    id: string;
    label: string;
    description: string;
    containerId: string;
    composite: boolean;
    clientRole: boolean;
};

type SelectRole = {
    id: string;
    name: string;
};

// moved to avoid a request per instance
export const rolesPromise: Promise<{ id: string; name: string }[]> = (async (): Promise<
    SelectRole[]
> => {
    const rolesResponse = await fetch(`${import.meta.env.VITE_API_BASE_URI}/roles`, {
        method: 'GET',
    });
    const rolesRaw = await rolesResponse.json();
    return rolesRaw.map((role: string): SelectRole => ({ id: role, name: role }));
})();

export const RoleComponent = ({
    control,
    message = 'Approve Role',
    name = 'roles',
    label = 'Role',
    required = false,
}: RoleComponentInterface) => {
    const intl = useIntl();
    // const { token } = useAuth();
    const [roles, setRoles] = useState<SelectRole[]>([]);

    useEffect(() => {
        rolesPromise.then(setRoles);
    }, []);

    return (
        roles && (
            <>
                <SelectFieldElement
                    control={control}
                    id={name}
                    key="roles"
                    name={name}
                    required={required}
                    labelId={intl.formatMessage({ defaultMessage: label, id: name })}
                    selectFieldTitle={{
                        formatId: name,
                        formatDefault: label,
                        inputLabelId: name,
                    }}
                    fieldOptions={{
                        keyId: 'id',
                        valueLabel: 'name',
                        nameLabel: 'name',
                        options: roles,
                        showEmptyOption: true,
                    }}
                />
                {message !== false && (
                    <InputLabelC id="approve-roles">
                        <FormattedMessage defaultMessage={message} id="approve-roles" />
                    </InputLabelC>
                )}
            </>
        )
    );
};
