import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateObjectSchemaMutationVariables = Types.Exact<{
  code: Types.Scalars['String'];
  disableDelete: Types.Scalars['Boolean'];
  disableEditCode: Types.Scalars['Boolean'];
  listName: Types.Scalars['String'];
  codeLabel: Types.Scalars['String'];
  showListNameInEdit: Types.Scalars['Boolean'];
}>;


export type CreateObjectSchemaMutation = { __typename?: 'Mutation', createObjectSchema?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };

export type DuplicateObjectSchemaMutationVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
  code: Types.Scalars['String'];
}>;


export type DuplicateObjectSchemaMutation = { __typename?: 'Mutation', duplicateObjectSchema?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };

export type DeleteObjectSchemaMutationVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
}>;


export type DeleteObjectSchemaMutation = { __typename?: 'Mutation', deleteObjectSchema?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };

export type UpdateObjectSchemaMutationVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
  code: Types.Scalars['String'];
  disableDelete: Types.Scalars['Boolean'];
  disableEditCode: Types.Scalars['Boolean'];
  listName: Types.Scalars['String'];
  codeLabel: Types.Scalars['String'];
  showListNameInEdit: Types.Scalars['Boolean'];
}>;


export type UpdateObjectSchemaMutation = { __typename?: 'Mutation', updateObjectSchema?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };

export type ObjectSchemasQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ObjectSchemasQuery = { __typename?: 'Query', objectSchemas: Array<{ __typename?: 'ObjectSchema', id: string, code: string, codeLabel?: string | null, showListNameInEdit?: boolean | null, disableDelete?: boolean | null, disableEditCode?: boolean | null, contentTypeGroups?: Array<{ __typename?: 'ContentTypeGroup', code: string, name: Array<{ __typename?: 'NameObject', languageIsoCode: string, value: string }>, contentTypes?: Array<{ __typename?: 'ContentType', hierarchy?: string | null, code: string, type: string, search?: string | null, link?: Array<string | null> | null, multiLanguage: boolean, multiValue: boolean, hidden?: boolean | null, name: Array<{ __typename?: 'NameObject', languageIsoCode: string, value: string }>, filters?: Array<{ __typename?: 'Filters', code: string, value: string, comparator: Types.SearchFilterOperator, id: string, editable?: boolean | null }> | null, dynamicFilters?: Array<{ __typename?: 'Filters', code: string, value: string, comparator: Types.SearchFilterOperator, id: string, editable?: boolean | null }> | null }> | null }> | null }> };

export type ObjectSchemaSearchQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ObjectSchemaSearchQuery = { __typename?: 'Query', objectSchemas: Array<{ __typename?: 'ObjectSchema', id: string, code: string, disableDelete?: boolean | null, disableEditCode?: boolean | null, contentTypeGroups?: Array<{ __typename?: 'ContentTypeGroup', code: string, name: Array<{ __typename?: 'NameObject', languageIsoCode: string, value: string }>, contentTypes?: Array<{ __typename?: 'ContentType', hierarchy?: string | null, code: string, type: string, search?: string | null, link?: Array<string | null> | null, multiLanguage: boolean, multiValue: boolean, hidden?: boolean | null, name: Array<{ __typename?: 'NameObject', languageIsoCode: string, value: string }>, filters?: Array<{ __typename?: 'Filters', code: string, value: string, comparator: Types.SearchFilterOperator, id: string, editable?: boolean | null }> | null, dynamicFilters?: Array<{ __typename?: 'Filters', code: string, value: string, comparator: Types.SearchFilterOperator, id: string, editable?: boolean | null }> | null, optionalValues?: Array<{ __typename?: 'SelectOptionValues', code: string, value: Array<{ __typename?: 'OptionValue', languageIsoCode: string, value: string }> }> | null }> | null }> | null }> };

export type NewContentTypeGroupMutationVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
  contentTypeGroup: Types.ContentTypeGroupI;
}>;


export type NewContentTypeGroupMutation = { __typename?: 'Mutation', addContentTypeGroup?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };

export type UpdateContentTypeGroupMutationVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
  updateContentTypeGroupInput: Types.UpdateContentTypeGroupInput;
}>;


export type UpdateContentTypeGroupMutation = { __typename?: 'Mutation', updateContentTypeGroup?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };

export type ContentTypeGroupQueryVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
  code: Types.Scalars['String'];
}>;


export type ContentTypeGroupQuery = { __typename?: 'Query', contentTypeGroup?: { __typename?: 'ContentTypeGroup', code: string, repeatable: boolean, roles?: string | null, approveRoles?: string | null, collapsed?: boolean | null, showLevel?: string | null, name: Array<{ __typename?: 'NameObject', languageIsoCode: string, value: string }>, contentTypes?: Array<{ __typename?: 'ContentType', code: string, type: string, hidden?: boolean | null, name: Array<{ __typename?: 'NameObject', languageIsoCode: string, value: string }> }> | null } | null };

export type DeleteContentTypeGroupMutationVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
  code: Types.Scalars['String'];
}>;


export type DeleteContentTypeGroupMutation = { __typename?: 'Mutation', deleteContentTypeGroup?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };

export type AddContentTypeMutationVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
  groupCode: Types.Scalars['String'];
  contentType: Types.ContentTypeInput;
}>;


export type AddContentTypeMutation = { __typename?: 'Mutation', addContentType?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };

export type UpdateContentTypeMutationVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
  groupCode: Types.Scalars['String'];
  updateContentTypeInput: Types.ContentTypeInput;
}>;


export type UpdateContentTypeMutation = { __typename?: 'Mutation', updateContentType?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };

export type ContentTypeQueryVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
  groupCode: Types.Scalars['String'];
  fieldCode: Types.Scalars['String'];
}>;


export type ContentTypeQuery = { __typename?: 'Query', contentType?: { __typename?: 'ContentType', code: string, depthFilter?: string | null, creatable?: boolean | null, multiLine?: boolean | null, isMainImage?: boolean | null, search?: string | null, link?: Array<string | null> | null, kind?: string | null, mandatory: boolean, disableNodeCreation?: boolean | null, multiLanguage: boolean, multiValue: boolean, hierarchy?: string | null, type: string, hidden?: boolean | null, regex?: string | null, readOnly?: boolean | null, enableRange?: boolean | null, name: Array<{ __typename?: 'NameObject', languageIsoCode: string, value: string }>, filters?: Array<{ __typename?: 'Filters', code: string, value: string, comparator: Types.SearchFilterOperator, id: string, editable?: boolean | null }> | null, dynamicFilters?: Array<{ __typename?: 'Filters', code: string, value: string, comparator: Types.SearchFilterOperator, id: string, editable?: boolean | null }> | null, mandatoryForSave?: { __typename?: 'MandatoryForSaveCondition', isMandatory?: boolean | null, option?: string | null, comparator?: string | null, value?: string | null } | null, optionalValues?: Array<{ __typename?: 'SelectOptionValues', code: string, value: Array<{ __typename?: 'OptionValue', languageIsoCode: string, value: string }> }> | null, dynamicRegex?: Array<{ __typename?: 'DynamicRegex', regex: string, option: string, comparator: string, value: string, id: string }> | null, visibility?: { __typename?: 'Visibility', operator?: string | null, visibilityRules?: Array<{ __typename?: 'VisibilityRule', option: string, comparator: string, value: string, id: string }> | null } | null } | null };

export type DeleteContentTypeMutationVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
  groupCode: Types.Scalars['String'];
  contentTypeCode: Types.Scalars['String'];
}>;


export type DeleteContentTypeMutation = { __typename?: 'Mutation', deleteContentType?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };

export type ReorderContentTypeGroupMutationVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
  order: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type ReorderContentTypeGroupMutation = { __typename?: 'Mutation', reorderContentTypeGroup?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };

export type ReorderContentTypeMutationVariables = Types.Exact<{
  aggregateId: Types.Scalars['String'];
  groupCode: Types.Scalars['String'];
  order: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type ReorderContentTypeMutation = { __typename?: 'Mutation', reorderContentType?: { __typename?: 'ObjectSchemaMutationResponse', code: string, success: boolean, message: string } | null };


export const CreateObjectSchemaDocument = gql`
    mutation CreateObjectSchema($code: String!, $disableDelete: Boolean!, $disableEditCode: Boolean!, $listName: String!, $codeLabel: String!, $showListNameInEdit: Boolean!) {
  createObjectSchema(
    code: $code
    disableDelete: $disableDelete
    disableEditCode: $disableEditCode
    listName: $listName
    codeLabel: $codeLabel
    showListNameInEdit: $showListNameInEdit
  ) {
    code
    success
    message
  }
}
    `;
export type CreateObjectSchemaMutationFn = Apollo.MutationFunction<CreateObjectSchemaMutation, CreateObjectSchemaMutationVariables>;

/**
 * __useCreateObjectSchemaMutation__
 *
 * To run a mutation, you first call `useCreateObjectSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateObjectSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createObjectSchemaMutation, { data, loading, error }] = useCreateObjectSchemaMutation({
 *   variables: {
 *      code: // value for 'code'
 *      disableDelete: // value for 'disableDelete'
 *      disableEditCode: // value for 'disableEditCode'
 *      listName: // value for 'listName'
 *      codeLabel: // value for 'codeLabel'
 *      showListNameInEdit: // value for 'showListNameInEdit'
 *   },
 * });
 */
export function useCreateObjectSchemaMutation(baseOptions?: Apollo.MutationHookOptions<CreateObjectSchemaMutation, CreateObjectSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateObjectSchemaMutation, CreateObjectSchemaMutationVariables>(CreateObjectSchemaDocument, options);
      }
export type CreateObjectSchemaMutationHookResult = ReturnType<typeof useCreateObjectSchemaMutation>;
export type CreateObjectSchemaMutationResult = Apollo.MutationResult<CreateObjectSchemaMutation>;
export type CreateObjectSchemaMutationOptions = Apollo.BaseMutationOptions<CreateObjectSchemaMutation, CreateObjectSchemaMutationVariables>;
export const DuplicateObjectSchemaDocument = gql`
    mutation DuplicateObjectSchema($aggregateId: String!, $code: String!) {
  duplicateObjectSchema(aggregateId: $aggregateId, code: $code) {
    code
    success
    message
  }
}
    `;
export type DuplicateObjectSchemaMutationFn = Apollo.MutationFunction<DuplicateObjectSchemaMutation, DuplicateObjectSchemaMutationVariables>;

/**
 * __useDuplicateObjectSchemaMutation__
 *
 * To run a mutation, you first call `useDuplicateObjectSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateObjectSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateObjectSchemaMutation, { data, loading, error }] = useDuplicateObjectSchemaMutation({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useDuplicateObjectSchemaMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateObjectSchemaMutation, DuplicateObjectSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateObjectSchemaMutation, DuplicateObjectSchemaMutationVariables>(DuplicateObjectSchemaDocument, options);
      }
export type DuplicateObjectSchemaMutationHookResult = ReturnType<typeof useDuplicateObjectSchemaMutation>;
export type DuplicateObjectSchemaMutationResult = Apollo.MutationResult<DuplicateObjectSchemaMutation>;
export type DuplicateObjectSchemaMutationOptions = Apollo.BaseMutationOptions<DuplicateObjectSchemaMutation, DuplicateObjectSchemaMutationVariables>;
export const DeleteObjectSchemaDocument = gql`
    mutation DeleteObjectSchema($aggregateId: String!) {
  deleteObjectSchema(aggregateId: $aggregateId) {
    code
    success
    message
  }
}
    `;
export type DeleteObjectSchemaMutationFn = Apollo.MutationFunction<DeleteObjectSchemaMutation, DeleteObjectSchemaMutationVariables>;

/**
 * __useDeleteObjectSchemaMutation__
 *
 * To run a mutation, you first call `useDeleteObjectSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteObjectSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteObjectSchemaMutation, { data, loading, error }] = useDeleteObjectSchemaMutation({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *   },
 * });
 */
export function useDeleteObjectSchemaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteObjectSchemaMutation, DeleteObjectSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteObjectSchemaMutation, DeleteObjectSchemaMutationVariables>(DeleteObjectSchemaDocument, options);
      }
export type DeleteObjectSchemaMutationHookResult = ReturnType<typeof useDeleteObjectSchemaMutation>;
export type DeleteObjectSchemaMutationResult = Apollo.MutationResult<DeleteObjectSchemaMutation>;
export type DeleteObjectSchemaMutationOptions = Apollo.BaseMutationOptions<DeleteObjectSchemaMutation, DeleteObjectSchemaMutationVariables>;
export const UpdateObjectSchemaDocument = gql`
    mutation UpdateObjectSchema($aggregateId: String!, $code: String!, $disableDelete: Boolean!, $disableEditCode: Boolean!, $listName: String!, $codeLabel: String!, $showListNameInEdit: Boolean!) {
  updateObjectSchema(
    aggregateId: $aggregateId
    code: $code
    disableDelete: $disableDelete
    disableEditCode: $disableEditCode
    listName: $listName
    codeLabel: $codeLabel
    showListNameInEdit: $showListNameInEdit
  ) {
    code
    success
    message
  }
}
    `;
export type UpdateObjectSchemaMutationFn = Apollo.MutationFunction<UpdateObjectSchemaMutation, UpdateObjectSchemaMutationVariables>;

/**
 * __useUpdateObjectSchemaMutation__
 *
 * To run a mutation, you first call `useUpdateObjectSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateObjectSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateObjectSchemaMutation, { data, loading, error }] = useUpdateObjectSchemaMutation({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *      code: // value for 'code'
 *      disableDelete: // value for 'disableDelete'
 *      disableEditCode: // value for 'disableEditCode'
 *      listName: // value for 'listName'
 *      codeLabel: // value for 'codeLabel'
 *      showListNameInEdit: // value for 'showListNameInEdit'
 *   },
 * });
 */
export function useUpdateObjectSchemaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateObjectSchemaMutation, UpdateObjectSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateObjectSchemaMutation, UpdateObjectSchemaMutationVariables>(UpdateObjectSchemaDocument, options);
      }
export type UpdateObjectSchemaMutationHookResult = ReturnType<typeof useUpdateObjectSchemaMutation>;
export type UpdateObjectSchemaMutationResult = Apollo.MutationResult<UpdateObjectSchemaMutation>;
export type UpdateObjectSchemaMutationOptions = Apollo.BaseMutationOptions<UpdateObjectSchemaMutation, UpdateObjectSchemaMutationVariables>;
export const ObjectSchemasDocument = gql`
    query ObjectSchemas {
  objectSchemas {
    id
    code
    codeLabel
    showListNameInEdit
    disableDelete
    disableEditCode
    contentTypeGroups {
      code
      name {
        languageIsoCode
        value
      }
      contentTypes {
        hierarchy
        code
        type
        search
        link
        multiLanguage
        multiValue
        name {
          languageIsoCode
          value
        }
        filters {
          code
          value
          comparator
          id
          editable
        }
        dynamicFilters {
          code
          value
          comparator
          id
          editable
        }
        hidden
      }
    }
  }
}
    `;

/**
 * __useObjectSchemasQuery__
 *
 * To run a query within a React component, call `useObjectSchemasQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectSchemasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectSchemasQuery({
 *   variables: {
 *   },
 * });
 */
export function useObjectSchemasQuery(baseOptions?: Apollo.QueryHookOptions<ObjectSchemasQuery, ObjectSchemasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectSchemasQuery, ObjectSchemasQueryVariables>(ObjectSchemasDocument, options);
      }
export function useObjectSchemasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectSchemasQuery, ObjectSchemasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectSchemasQuery, ObjectSchemasQueryVariables>(ObjectSchemasDocument, options);
        }
export type ObjectSchemasQueryHookResult = ReturnType<typeof useObjectSchemasQuery>;
export type ObjectSchemasLazyQueryHookResult = ReturnType<typeof useObjectSchemasLazyQuery>;
export type ObjectSchemasQueryResult = Apollo.QueryResult<ObjectSchemasQuery, ObjectSchemasQueryVariables>;
export const ObjectSchemaSearchDocument = gql`
    query ObjectSchemaSearch {
  objectSchemas {
    id
    code
    disableDelete
    disableEditCode
    contentTypeGroups {
      code
      name {
        languageIsoCode
        value
      }
      contentTypes {
        hierarchy
        code
        type
        search
        link
        multiLanguage
        multiValue
        name {
          languageIsoCode
          value
        }
        filters {
          code
          value
          comparator
          id
          editable
        }
        dynamicFilters {
          code
          value
          comparator
          id
          editable
        }
        hidden
        optionalValues {
          code
          value {
            languageIsoCode
            value
          }
        }
      }
    }
  }
}
    `;

/**
 * __useObjectSchemaSearchQuery__
 *
 * To run a query within a React component, call `useObjectSchemaSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectSchemaSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectSchemaSearchQuery({
 *   variables: {
 *   },
 * });
 */
export function useObjectSchemaSearchQuery(baseOptions?: Apollo.QueryHookOptions<ObjectSchemaSearchQuery, ObjectSchemaSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectSchemaSearchQuery, ObjectSchemaSearchQueryVariables>(ObjectSchemaSearchDocument, options);
      }
export function useObjectSchemaSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectSchemaSearchQuery, ObjectSchemaSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectSchemaSearchQuery, ObjectSchemaSearchQueryVariables>(ObjectSchemaSearchDocument, options);
        }
export type ObjectSchemaSearchQueryHookResult = ReturnType<typeof useObjectSchemaSearchQuery>;
export type ObjectSchemaSearchLazyQueryHookResult = ReturnType<typeof useObjectSchemaSearchLazyQuery>;
export type ObjectSchemaSearchQueryResult = Apollo.QueryResult<ObjectSchemaSearchQuery, ObjectSchemaSearchQueryVariables>;
export const NewContentTypeGroupDocument = gql`
    mutation NewContentTypeGroup($aggregateId: String!, $contentTypeGroup: ContentTypeGroupI!) {
  addContentTypeGroup(
    aggregateId: $aggregateId
    contentTypeGroup: $contentTypeGroup
  ) {
    code
    success
    message
  }
}
    `;
export type NewContentTypeGroupMutationFn = Apollo.MutationFunction<NewContentTypeGroupMutation, NewContentTypeGroupMutationVariables>;

/**
 * __useNewContentTypeGroupMutation__
 *
 * To run a mutation, you first call `useNewContentTypeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewContentTypeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newContentTypeGroupMutation, { data, loading, error }] = useNewContentTypeGroupMutation({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *      contentTypeGroup: // value for 'contentTypeGroup'
 *   },
 * });
 */
export function useNewContentTypeGroupMutation(baseOptions?: Apollo.MutationHookOptions<NewContentTypeGroupMutation, NewContentTypeGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewContentTypeGroupMutation, NewContentTypeGroupMutationVariables>(NewContentTypeGroupDocument, options);
      }
export type NewContentTypeGroupMutationHookResult = ReturnType<typeof useNewContentTypeGroupMutation>;
export type NewContentTypeGroupMutationResult = Apollo.MutationResult<NewContentTypeGroupMutation>;
export type NewContentTypeGroupMutationOptions = Apollo.BaseMutationOptions<NewContentTypeGroupMutation, NewContentTypeGroupMutationVariables>;
export const UpdateContentTypeGroupDocument = gql`
    mutation UpdateContentTypeGroup($aggregateId: String!, $updateContentTypeGroupInput: updateContentTypeGroupInput!) {
  updateContentTypeGroup(
    aggregateId: $aggregateId
    updateContentTypeGroupInput: $updateContentTypeGroupInput
  ) {
    code
    success
    message
  }
}
    `;
export type UpdateContentTypeGroupMutationFn = Apollo.MutationFunction<UpdateContentTypeGroupMutation, UpdateContentTypeGroupMutationVariables>;

/**
 * __useUpdateContentTypeGroupMutation__
 *
 * To run a mutation, you first call `useUpdateContentTypeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentTypeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentTypeGroupMutation, { data, loading, error }] = useUpdateContentTypeGroupMutation({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *      updateContentTypeGroupInput: // value for 'updateContentTypeGroupInput'
 *   },
 * });
 */
export function useUpdateContentTypeGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContentTypeGroupMutation, UpdateContentTypeGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContentTypeGroupMutation, UpdateContentTypeGroupMutationVariables>(UpdateContentTypeGroupDocument, options);
      }
export type UpdateContentTypeGroupMutationHookResult = ReturnType<typeof useUpdateContentTypeGroupMutation>;
export type UpdateContentTypeGroupMutationResult = Apollo.MutationResult<UpdateContentTypeGroupMutation>;
export type UpdateContentTypeGroupMutationOptions = Apollo.BaseMutationOptions<UpdateContentTypeGroupMutation, UpdateContentTypeGroupMutationVariables>;
export const ContentTypeGroupDocument = gql`
    query ContentTypeGroup($aggregateId: String!, $code: String!) {
  contentTypeGroup(aggregateId: $aggregateId, code: $code) {
    code
    name {
      languageIsoCode
      value
    }
    repeatable
    roles
    approveRoles
    collapsed
    showLevel
    contentTypes {
      code
      type
      name {
        languageIsoCode
        value
      }
      hidden
    }
  }
}
    `;

/**
 * __useContentTypeGroupQuery__
 *
 * To run a query within a React component, call `useContentTypeGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentTypeGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentTypeGroupQuery({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useContentTypeGroupQuery(baseOptions: Apollo.QueryHookOptions<ContentTypeGroupQuery, ContentTypeGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentTypeGroupQuery, ContentTypeGroupQueryVariables>(ContentTypeGroupDocument, options);
      }
export function useContentTypeGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentTypeGroupQuery, ContentTypeGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentTypeGroupQuery, ContentTypeGroupQueryVariables>(ContentTypeGroupDocument, options);
        }
export type ContentTypeGroupQueryHookResult = ReturnType<typeof useContentTypeGroupQuery>;
export type ContentTypeGroupLazyQueryHookResult = ReturnType<typeof useContentTypeGroupLazyQuery>;
export type ContentTypeGroupQueryResult = Apollo.QueryResult<ContentTypeGroupQuery, ContentTypeGroupQueryVariables>;
export const DeleteContentTypeGroupDocument = gql`
    mutation DeleteContentTypeGroup($aggregateId: String!, $code: String!) {
  deleteContentTypeGroup(aggregateId: $aggregateId, code: $code) {
    code
    success
    message
  }
}
    `;
export type DeleteContentTypeGroupMutationFn = Apollo.MutationFunction<DeleteContentTypeGroupMutation, DeleteContentTypeGroupMutationVariables>;

/**
 * __useDeleteContentTypeGroupMutation__
 *
 * To run a mutation, you first call `useDeleteContentTypeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentTypeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentTypeGroupMutation, { data, loading, error }] = useDeleteContentTypeGroupMutation({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useDeleteContentTypeGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContentTypeGroupMutation, DeleteContentTypeGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContentTypeGroupMutation, DeleteContentTypeGroupMutationVariables>(DeleteContentTypeGroupDocument, options);
      }
export type DeleteContentTypeGroupMutationHookResult = ReturnType<typeof useDeleteContentTypeGroupMutation>;
export type DeleteContentTypeGroupMutationResult = Apollo.MutationResult<DeleteContentTypeGroupMutation>;
export type DeleteContentTypeGroupMutationOptions = Apollo.BaseMutationOptions<DeleteContentTypeGroupMutation, DeleteContentTypeGroupMutationVariables>;
export const AddContentTypeDocument = gql`
    mutation AddContentType($aggregateId: String!, $groupCode: String!, $contentType: ContentTypeInput!) {
  addContentType(
    aggregateId: $aggregateId
    groupCode: $groupCode
    contentType: $contentType
  ) {
    code
    success
    message
  }
}
    `;
export type AddContentTypeMutationFn = Apollo.MutationFunction<AddContentTypeMutation, AddContentTypeMutationVariables>;

/**
 * __useAddContentTypeMutation__
 *
 * To run a mutation, you first call `useAddContentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContentTypeMutation, { data, loading, error }] = useAddContentTypeMutation({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *      groupCode: // value for 'groupCode'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useAddContentTypeMutation(baseOptions?: Apollo.MutationHookOptions<AddContentTypeMutation, AddContentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddContentTypeMutation, AddContentTypeMutationVariables>(AddContentTypeDocument, options);
      }
export type AddContentTypeMutationHookResult = ReturnType<typeof useAddContentTypeMutation>;
export type AddContentTypeMutationResult = Apollo.MutationResult<AddContentTypeMutation>;
export type AddContentTypeMutationOptions = Apollo.BaseMutationOptions<AddContentTypeMutation, AddContentTypeMutationVariables>;
export const UpdateContentTypeDocument = gql`
    mutation UpdateContentType($aggregateId: String!, $groupCode: String!, $updateContentTypeInput: ContentTypeInput!) {
  updateContentType(
    aggregateId: $aggregateId
    groupCode: $groupCode
    updateContentTypeInput: $updateContentTypeInput
  ) {
    code
    success
    message
  }
}
    `;
export type UpdateContentTypeMutationFn = Apollo.MutationFunction<UpdateContentTypeMutation, UpdateContentTypeMutationVariables>;

/**
 * __useUpdateContentTypeMutation__
 *
 * To run a mutation, you first call `useUpdateContentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentTypeMutation, { data, loading, error }] = useUpdateContentTypeMutation({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *      groupCode: // value for 'groupCode'
 *      updateContentTypeInput: // value for 'updateContentTypeInput'
 *   },
 * });
 */
export function useUpdateContentTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContentTypeMutation, UpdateContentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContentTypeMutation, UpdateContentTypeMutationVariables>(UpdateContentTypeDocument, options);
      }
export type UpdateContentTypeMutationHookResult = ReturnType<typeof useUpdateContentTypeMutation>;
export type UpdateContentTypeMutationResult = Apollo.MutationResult<UpdateContentTypeMutation>;
export type UpdateContentTypeMutationOptions = Apollo.BaseMutationOptions<UpdateContentTypeMutation, UpdateContentTypeMutationVariables>;
export const ContentTypeDocument = gql`
    query ContentType($aggregateId: String!, $groupCode: String!, $fieldCode: String!) {
  contentType(
    aggregateId: $aggregateId
    groupCode: $groupCode
    fieldCode: $fieldCode
  ) {
    code
    name {
      languageIsoCode
      value
    }
    depthFilter
    filters {
      code
      value
      comparator
      id
      editable
    }
    dynamicFilters {
      code
      value
      comparator
      id
      editable
    }
    creatable
    multiLine
    isMainImage
    search
    link
    kind
    mandatory
    disableNodeCreation
    mandatoryForSave {
      isMandatory
      option
      comparator
      value
    }
    multiLanguage
    multiValue
    hierarchy
    optionalValues {
      code
      value {
        languageIsoCode
        value
      }
    }
    type
    hidden
    regex
    dynamicRegex {
      regex
      option
      comparator
      value
      id
    }
    visibility {
      operator
      visibilityRules {
        option
        comparator
        value
        id
      }
    }
    readOnly
    enableRange
  }
}
    `;

/**
 * __useContentTypeQuery__
 *
 * To run a query within a React component, call `useContentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentTypeQuery({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *      groupCode: // value for 'groupCode'
 *      fieldCode: // value for 'fieldCode'
 *   },
 * });
 */
export function useContentTypeQuery(baseOptions: Apollo.QueryHookOptions<ContentTypeQuery, ContentTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentTypeQuery, ContentTypeQueryVariables>(ContentTypeDocument, options);
      }
export function useContentTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentTypeQuery, ContentTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentTypeQuery, ContentTypeQueryVariables>(ContentTypeDocument, options);
        }
export type ContentTypeQueryHookResult = ReturnType<typeof useContentTypeQuery>;
export type ContentTypeLazyQueryHookResult = ReturnType<typeof useContentTypeLazyQuery>;
export type ContentTypeQueryResult = Apollo.QueryResult<ContentTypeQuery, ContentTypeQueryVariables>;
export const DeleteContentTypeDocument = gql`
    mutation DeleteContentType($aggregateId: String!, $groupCode: String!, $contentTypeCode: String!) {
  deleteContentType(
    aggregateId: $aggregateId
    groupCode: $groupCode
    contentTypeCode: $contentTypeCode
  ) {
    code
    success
    message
  }
}
    `;
export type DeleteContentTypeMutationFn = Apollo.MutationFunction<DeleteContentTypeMutation, DeleteContentTypeMutationVariables>;

/**
 * __useDeleteContentTypeMutation__
 *
 * To run a mutation, you first call `useDeleteContentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentTypeMutation, { data, loading, error }] = useDeleteContentTypeMutation({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *      groupCode: // value for 'groupCode'
 *      contentTypeCode: // value for 'contentTypeCode'
 *   },
 * });
 */
export function useDeleteContentTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContentTypeMutation, DeleteContentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContentTypeMutation, DeleteContentTypeMutationVariables>(DeleteContentTypeDocument, options);
      }
export type DeleteContentTypeMutationHookResult = ReturnType<typeof useDeleteContentTypeMutation>;
export type DeleteContentTypeMutationResult = Apollo.MutationResult<DeleteContentTypeMutation>;
export type DeleteContentTypeMutationOptions = Apollo.BaseMutationOptions<DeleteContentTypeMutation, DeleteContentTypeMutationVariables>;
export const ReorderContentTypeGroupDocument = gql`
    mutation ReorderContentTypeGroup($aggregateId: String!, $order: [String!]!) {
  reorderContentTypeGroup(aggregateId: $aggregateId, order: $order) {
    code
    success
    message
  }
}
    `;
export type ReorderContentTypeGroupMutationFn = Apollo.MutationFunction<ReorderContentTypeGroupMutation, ReorderContentTypeGroupMutationVariables>;

/**
 * __useReorderContentTypeGroupMutation__
 *
 * To run a mutation, you first call `useReorderContentTypeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderContentTypeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderContentTypeGroupMutation, { data, loading, error }] = useReorderContentTypeGroupMutation({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useReorderContentTypeGroupMutation(baseOptions?: Apollo.MutationHookOptions<ReorderContentTypeGroupMutation, ReorderContentTypeGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderContentTypeGroupMutation, ReorderContentTypeGroupMutationVariables>(ReorderContentTypeGroupDocument, options);
      }
export type ReorderContentTypeGroupMutationHookResult = ReturnType<typeof useReorderContentTypeGroupMutation>;
export type ReorderContentTypeGroupMutationResult = Apollo.MutationResult<ReorderContentTypeGroupMutation>;
export type ReorderContentTypeGroupMutationOptions = Apollo.BaseMutationOptions<ReorderContentTypeGroupMutation, ReorderContentTypeGroupMutationVariables>;
export const ReorderContentTypeDocument = gql`
    mutation ReorderContentType($aggregateId: String!, $groupCode: String!, $order: [String!]!) {
  reorderContentType(
    aggregateId: $aggregateId
    groupCode: $groupCode
    order: $order
  ) {
    code
    success
    message
  }
}
    `;
export type ReorderContentTypeMutationFn = Apollo.MutationFunction<ReorderContentTypeMutation, ReorderContentTypeMutationVariables>;

/**
 * __useReorderContentTypeMutation__
 *
 * To run a mutation, you first call `useReorderContentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderContentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderContentTypeMutation, { data, loading, error }] = useReorderContentTypeMutation({
 *   variables: {
 *      aggregateId: // value for 'aggregateId'
 *      groupCode: // value for 'groupCode'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useReorderContentTypeMutation(baseOptions?: Apollo.MutationHookOptions<ReorderContentTypeMutation, ReorderContentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderContentTypeMutation, ReorderContentTypeMutationVariables>(ReorderContentTypeDocument, options);
      }
export type ReorderContentTypeMutationHookResult = ReturnType<typeof useReorderContentTypeMutation>;
export type ReorderContentTypeMutationResult = Apollo.MutationResult<ReorderContentTypeMutation>;
export type ReorderContentTypeMutationOptions = Apollo.BaseMutationOptions<ReorderContentTypeMutation, ReorderContentTypeMutationVariables>;