import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export default styled(({ className, minWidth, ...props }: TooltipProps & { minWidth?: string }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, minWidth }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        minWidth,
    },
}));
