import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { Language } from '@src/__generated__/types';
import { createContext, ReactNode, useContext, useMemo } from 'react';

import { useLanguagesQuery } from '../../hooks/graphql/language.generated';

/* CONTEXT */

type ILanguageCtx = {
    defaultLanguage: string;
    languages: Language['languages'] | null;
    refetch:
        | ((
              variables?: Partial<OperationVariables> | undefined,
          ) => Promise<ApolloQueryResult<Language>>)
        | (() => void);
};

const LanguageCtx = createContext<ILanguageCtx | null>(null);

/* HOOK THAT PROVIDE CONTEXT */

export const useLanguageCtx = (): ILanguageCtx => {
    const context = useContext(LanguageCtx);

    if (!context) {
        throw new Error('useLanguageCtx must be used within a LanguageProvider');
    }

    return context;
};

/* CONTEXT PROVIDER */

export function LanguageProvider({ children }: { children: ReactNode }) {
    const { data, refetch } = useLanguagesQuery();

    const contextValue = useMemo(() => {
        if (!data || !data.languages?.length || !data.languages[0]) {
            return null;
        }
        return {
            languages: data.languages[0].languages,
            defaultLanguage: data.languages[0].defaultLanguage,
            refetch,
        } satisfies ILanguageCtx;
    }, [data]);

    return !contextValue ? null : (
        <LanguageCtx.Provider value={contextValue}>{children}</LanguageCtx.Provider>
    );
}
