import { useLanguageCtx } from '@modules/language';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { SelectFieldProps } from '@src/assets/types';
import { Option, SelectFieldOptions } from '@src/assets/types/form/SelectField.types';
import { useContext, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { PublishContentContext } from '../../modules/hierarchy/publish/PublishContentContext';

interface TransformedOption {
    code: string | null;
    label: string;
}

interface TransformOptions {
    options: SelectFieldOptions['options'];
    valueLabel: SelectFieldOptions['valueLabel'];
    nameLabel: SelectFieldOptions['nameLabel'];
    dataCy: SelectFieldOptions['dataCy'];
    isEditNode: boolean;
    isMultiValue?: boolean;
    showEmptyOption?: boolean;
    defaultLabel?: string;
    defaultLanguage: string;
}

const getOptionValue = (option: Option | string, property: string | undefined): string => {
    if (property && typeof option === 'object' && option !== null && property in option) {
        return option[property];
    }
    return String(option);
};

function getNameFromOption(option: Option, defaultLanguage: string, nameLabel: string) {
    if (!Array.isArray(option.value)) {
        return '';
    }

    const foundValue = option.value.find(v => v.languageIsoCode === defaultLanguage);
    return foundValue?.[nameLabel] ?? option.value[0][nameLabel] ?? '';
}

// Function to transform the options
const transformOptions = ({
    options,
    valueLabel,
    nameLabel,
    isEditNode,
    isMultiValue,
    showEmptyOption,
    defaultLabel,
    defaultLanguage,
}: TransformOptions): TransformedOption[] => {
    if (!options) {
        return [];
    }

    const result: TransformedOption[] = [];

    if (showEmptyOption && !isMultiValue) {
        result.push({
            code: defaultLabel ?? null,
            label: defaultLabel ?? 'None',
        });
    }

    result.push(
        ...options.map<TransformedOption>(option => ({
            code: getOptionValue(option, valueLabel),
            label:
                isEditNode && nameLabel && typeof option === 'object'
                    ? getNameFromOption(option, defaultLanguage, nameLabel)
                    : getOptionValue(option, nameLabel),
        })),
    );

    return result;
};

function SelectFieldElement(props: SelectFieldProps) {
    const {
        id,
        name,
        defaultValue = '',
        control,
        isMultiValue = false,
        fieldOptions,
        selectFieldTitle,
        defaultLabel,
        fullWidth = true,
        readOnly = false,
        disabled = false,
        sxCustom = {},
        sxParent = {},
        sxTheme,
        InputProps,
        onChange,
        labelId,
        SelectProps,
        ...rest
    } = props;

    const intl = useIntl();

    const { field } = useController({
        name,
        control,
        defaultValue,
    });

    const [filterValue, setFilterValue] = useState('');

    const { defaultLanguage } = useLanguageCtx();
    const isPublishContent = useContext(PublishContentContext);

    const { valueLabel, nameLabel, dataCy, options, showEmptyOption, isEditNode } = fieldOptions;

    const { formatId, formatDefault, sxTitleCustom = {}, sxTitleTheme } = selectFieldTitle;

    const transformedOptions = transformOptions({
        options,
        valueLabel,
        nameLabel,
        dataCy,
        isEditNode: Boolean(isEditNode),
        showEmptyOption,
        isMultiValue,
        defaultLabel,
        defaultLanguage,
    });

    const autocompleteValue = useMemo(() => {
        // Handle initial multi value
        if (isMultiValue) {
            return transformedOptions.filter(o => field.value.find((v: any) => v === o.code));
        }
        // Handle initial value
        const option = transformedOptions.find(o => o.code === field.value);
        return option?.code ? option : null;
    }, [isMultiValue, field, options]);

    return (
        <FormControl
            sx={[
                theme => ({
                    marginBlock: theme.spacing(1),
                    minWidth: 230,
                    ...(sxTitleTheme && sxTitleTheme(theme)),
                    ...sxParent,
                }),
                sxTitleCustom,
            ]}
            {...(isPublishContent && {
                variant: 'standard',
            })}
        >
            <Autocomplete
                id={id}
                multiple={isMultiValue}
                fullWidth={fullWidth}
                disabled={disabled || readOnly}
                value={autocompleteValue}
                onChange={(_, value) => {
                    if (isMultiValue && Array.isArray(value)) {
                        field.onChange(value.map((v: any) => v.code));
                    } else if (value && 'code' in value) {
                        field.onChange(value.code);
                    } else {
                        field.onChange('');
                    }
                    onChange?.(_, value);
                }}
                options={transformedOptions}
                getOptionLabel={opt => opt.label}
                filterOptions={opts =>
                    opts.filter(o => o.label.toLowerCase().includes(filterValue.toLowerCase()))
                }
                handleHomeEndKeys
                disablePortal
                selectOnFocus
                blurOnSelect
                clearOnBlur
                {...rest}
                renderInput={params => (
                    <TextField
                        ref={field.ref}
                        variant="outlined"
                        name={field.name}
                        onChange={e => setFilterValue(e.target.value)}
                        label={intl.formatMessage({
                            defaultMessage: formatDefault,
                            id: formatId,
                        })}
                        sx={[
                            theme => ({
                                minWidth: 222,
                                ...(sxTheme && sxTheme(theme)),
                            }),
                            sxCustom,
                        ]}
                        {...params}
                    />
                )}
                popupIcon={<KeyboardArrowDown />}
            />
        </FormControl>
    );
}

export default SelectFieldElement;
